"use client";

import { useRouter } from "next/navigation";
import * as React from "react";
import { useImmer } from "use-immer";
function Page() {
  const router = useRouter();
  const [state, mutate] = useImmer({
    accessToken: ""
  });
  async function login() {
    await fetch("/api/login", {
      method: "POST",
      headers: {
        ["Content-Type"]: "application/json"
      },
      body: JSON.stringify(state)
    });
    router.push("/");
  }
  return <div className={"d8wxvta"}>
      <div className={"d1ef2lka"}>
        <input value={state.accessToken} onChange={ev => {
        const accessToken = ev.currentTarget.value;
        mutate(draft => {
          draft.accessToken = accessToken;
        });
      }} />
        <button onClick={login}>Log in</button>
      </div>
    </div>;
}
export default Page;

require("./page.linaria.module.css");